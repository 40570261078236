



























































































































































































































































































































































/* eslint-disable @typescript-eslint/naming-convention */
import { Vue, Component, Watch } from 'vue-property-decorator';
import compareCard from '@/components/CompareCard/index.vue';
import portraitPrivcar from '@/components/portraitPrivcar/index.vue';
import {
  shoppingMallArrival,
  consumeAbility,
  differentSexConsumeDetails,
  differentAgeConsumeDetails,
  marketPenetration,
} from '@/utils/echarts/echarts';
import {
  PassengerFlowTopsParams,
  PerAgePortraitSParams,
  TotalPassengerFlowParams,
} from '@/types/store';
import {
  portraitByMallId,
  mallAnalysisConsumptionAbility,
  mallTotalFlow,
  getGridPerAgePortraitInterceptS,
} from '@/services/api';
import tdesignFooter from '@/components/GlobalFooter/index.vue';
import { deepClone } from '@/utils';

@Component({
  components: {
    compareCard,
    portraitPrivcar,
    tdesignFooter,
  },
})
export default class PortraitEconomy extends Vue {
  slotEcharts = true;
  slotcontent = false;
  $dayjs: any;
  excelArr: any[] = [];
  arrivalshow = true;
  arrival = {};
  restaurantshow = true;
  restaurantLevel = {};
  restaurantLevelshow = true;
  phoneType = {};
  phoneTypeshow = true;
  phonePrice = {};
  phonePriceshow = true;
  order = true;
  reset = false;
  show = true;
  show1 = true;
  show2 = true;
  show3 = true;
  show4 = true;
  show5 = true;
  dataStatus0 = 0;
  dataStatus1 = 0;
  dataStatus2 = 0;
  dataStatus3 = 0;
  dataStatus4 = 0;
  dataStatus5 = 0;
  dataStatusRestaurantshow = 0;
  dataStatusRestaurantLevelshow = 0;
  dataStatusPhoneTypeshow = 0;
  dataStatusPhonePriceshow = 0;
  dataStatusArrivalshow = 0;
  nodeDataText = '数据量过低，由于数据统计样本失衡及用户数据隐私策略保护，画像标签不展示。';
  initData = [];
  duration = this.$store.state.mall.duration;
  mall_id = this.$store.state.mall.mallId;
  // login = this.$store.state.global.login;
  date = this.$store.state.mall.date;
  consumptionlevel = {};
  frequencyOfVisits = {};
  displayData = [];
  vehicle = {};
  housingPrice = {};
  ageConsumeDetails = {};
  ageConsumeDistribution = {};
  restaurantConsume = {};
  pravcar = Vue.prototype.$GLOBAL.privacyInfo;
  mallTotalStatus = false;
  mallTotal: any;
  $beaconAction: any;

  buriedPointHandler(buriedPoint: string): void{
    this.$beaconAction.onUserAction(buriedPoint, {});
  }
  async pageHandler(): Promise<void> {
    this.$stores.global.setDownAble(false); // 数据全部加载完全后才允许下载
    this.show = true;
    this.show1 = true;
    this.show2 = true;
    this.show3 = true;
    this.show4 = true;
    this.show5 = true;
    this.restaurantshow = true;
    this.restaurantLevelshow = true;
    this.phoneTypeshow = true;
    this.mall_id = this.$store.state.mall.mallId;
    this.date = this.$store.state.mall.date;
    this.duration = this.$store.state.mall.duration;
    const mallTotalFlowParams: TotalPassengerFlowParams = {
      mall_id: this.mall_id,
      duration: this.duration,
      date: this.date,
      type: 1,
      property: 1,
    };
    const mallTotalRes = await mallTotalFlow(mallTotalFlowParams);
    this.mallTotal = mallTotalRes.sum;
    this.mallTotalStatus = this.mallTotal < 100;
    if (this.mallTotalStatus) {
      return;
    }
    const PerAgePortraitSParams: PerAgePortraitSParams = {
      mall_id: this.mall_id,
      date: this.date,
      duration: this.duration,
      type: 102,
      j_type: 1,
      sum_type: 0,
    };

    PerAgePortraitSParams.type = 107;
    PerAgePortraitSParams.j_type = 0;
    PerAgePortraitSParams.sum_type = 0;
    const colorAry = ['#0062ff', '#ee5c3d', '#40c8f5', '#74d857', '#846bce'];
    const title1 = '消费水平';
    const title1s = '消费水平占比';
    const portraitRes1 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title1,
      title1s,
      consumeAbility,
      colorAry,
    );
    this.consumptionlevel = portraitRes1.option;
    this.dataStatus0 = portraitRes1.dataStatus;
    this.show = false;
    // 商场到访频次
    PerAgePortraitSParams.type = 108;
    PerAgePortraitSParams.j_type = 0;
    PerAgePortraitSParams.sum_type = 0;
    const title2 = '商场到访频次';
    const portraitRes2 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title2,
      title1s,
      shoppingMallArrival,
      [''],
    );
    this.frequencyOfVisits = portraitRes2.option;
    this.show1 = false;
    this.dataStatus1 = portraitRes2.dataStatus;
    // 是否有车
    PerAgePortraitSParams.type = 112;
    PerAgePortraitSParams.j_type = 0;
    PerAgePortraitSParams.sum_type = 0;
    const title3 = '是否有车';
    const title3s = '';
    const portraitRes3 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title3,
      title3s,
      consumeAbility,
      colorAry,
    );

    this.vehicle = portraitRes3.option;
    this.show2 = false;
    this.dataStatus2 = portraitRes3.dataStatus;
    // 居住社区房价
    PerAgePortraitSParams.type = 111;
    PerAgePortraitSParams.j_type = 0;
    PerAgePortraitSParams.sum_type = 0;
    const title4 = '居住社区房价';
    const portraitRes4 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title4,
      title1s,
      shoppingMallArrival,
      [''],
    );
    this.housingPrice = portraitRes4.option;
    this.show3 = false;
    this.dataStatus3 = portraitRes4.dataStatus;
    // 餐饮消费水平
    PerAgePortraitSParams.type = 110;
    PerAgePortraitSParams.j_type = 0;
    PerAgePortraitSParams.sum_type = 0;
    const portraitRes5 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      '餐饮消费水平',
      title1s,
      shoppingMallArrival,
      [''],
    );
    this.restaurantLevel = portraitRes5.option;
    this.restaurantLevelshow = false;
    this.dataStatusRestaurantLevelshow = portraitRes5.dataStatus;
    // 餐饮消费频次
    PerAgePortraitSParams.type = 109;
    PerAgePortraitSParams.j_type = 0;
    PerAgePortraitSParams.sum_type = 0;
    const portraitRes6 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      '餐饮消费频次',
      title1s,
      shoppingMallArrival,
      [''],
    );
    this.restaurantConsume = portraitRes6.option;
    this.restaurantshow = false;
    this.dataStatusRestaurantshow = portraitRes6.dataStatus;
    // 到访4s店品牌
    const arrivalParams = {
      mall_id: this.mall_id,
      date: this.date,
      type: 114,
      duration: this.duration,
      order_by: 'num',
      top_start: '0',
      top_end: '10',
    };
    const portraitRes7 = await this.arrivalAgePortrait(
      arrivalParams,
      '到访4s店品牌',
      title1s,
      shoppingMallArrival,
      [''],
    );
    // percent;
    this.arrival = portraitRes7.option;
    this.arrivalshow = false;
    this.dataStatusArrivalshow = portraitRes7.dataStatus;
    // 手机品牌
    PerAgePortraitSParams.type = 115;
    PerAgePortraitSParams.j_type = 0;
    PerAgePortraitSParams.sum_type = 0;
    const portraitRes8 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      '手机品牌',
      title1s,
      shoppingMallArrival,
      [''],
    );
    this.phoneType = portraitRes8.option;
    this.phoneTypeshow = false;
    this.dataStatusPhoneTypeshow = portraitRes8.dataStatus;
    // 手机价格
    PerAgePortraitSParams.type = 116;
    PerAgePortraitSParams.j_type = 2;
    PerAgePortraitSParams.sum_type = 0;
    const portraitRes9 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      '手机价格',
      title1s,
      shoppingMallArrival,
      [''],
    );
    this.phonePrice = portraitRes9.option;
    this.phonePriceshow = false;
    this.dataStatusPhonePriceshow = portraitRes9.dataStatus;

    // 商场客群性别年龄分布
    const params: PassengerFlowTopsParams = {
      mall_id: this.mall_id,
      date: this.date,
      duration: this.duration,
    };
    const ageNumRes = await this.getGridMallAnalysisConsumptionAbility(params);
    this.displayData = ageNumRes;
    this.reset = false;
    this.ageConsumeDistribution = ageNumRes.option;
    this.ageConsumeDetails = ageNumRes.option2;
    this.show5 = false;
    this.dataStatus5 = ageNumRes.dataStatus;
    this.show4 = false;
    this.dataStatus4 = ageNumRes.dataStatus2;
    this.excelArr = [
      portraitRes1.datas,
      portraitRes2.datas,
      portraitRes3.datas,
      portraitRes4.datas,
      portraitRes5.datas,
      portraitRes6.datas,
      portraitRes7.datas,
      portraitRes8.datas,
      portraitRes9.datas,
      ageNumRes.datas[0],
      ageNumRes.datas[1],
    ];
    this.$stores.global.setDownAble(true); // 数据全部加载完全后才允许下载
  }
  inOrder(): void {
    this.reset = !this.reset;
    if (this.reset) {
      const displaypercent = deepClone(this.displayData);
      const displaypercentTwo = deepClone(this.displayData);
      this.orderDataRender(displaypercent, displaypercentTwo, 'num');
      this.ageConsumeDistribution = marketPenetration(
        displaypercent.datas[0].map((i: any) => i.text),
        displaypercentTwo.datas[0].map((i: any) => i.num),
        displaypercent.datas[0].map((i: any) => i.num),
        '男',
        '女',
        '消费指数',
      );
    } else {
      this.ageConsumeDistribution = differentAgeConsumeDetails(
        (this.initData as any).totalConsumption.texts,
        (this.initData as any).totalConsumption.manData,
        (this.initData as any).totalConsumption.menData,
        2,
        '年龄分布', '消费指数',
      );
    }
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  orderDataRender(val1: any, val2: any, sortVal: any): void {
    val1.datas[0].sort((a: any, b: any) => (b as any)[sortVal] - (a as any)[sortVal]);
    val2.datas[0].sort((a: any, b: any) => (b as any)[sortVal] - (a as any)[sortVal]);
    val1.datas[0].forEach((i: any) => {
      if (i.text.includes('男')) {
        // eslint-disable-next-line no-param-reassign
        i[sortVal] = '';
      }
    });
    val2.datas[0].forEach((i: any) => {
      if (i.text.includes('女')) {
        // eslint-disable-next-line no-param-reassign
        i[sortVal] = '';
      }
    });
  }
  async getGridMallAnalysisConsumptionAbility(params: PassengerFlowTopsParams): Promise<any> {
    const res = await mallAnalysisConsumptionAbility(params);
    this.initData = res;
    const { totalConsumption, consumptionIndex, datas } = res;
    const { manData, menData, texts } = totalConsumption;
    const {
      centerAry,
      highAry,
      higherAry,
      lowAry,
      lowerAry,
      texts: texts2,
    } = consumptionIndex;
    const option2 = differentSexConsumeDetails(
      texts2,
      higherAry,
      highAry,
      centerAry,
      lowerAry,
      lowAry,
    );
    const option = differentAgeConsumeDetails(texts, manData, menData, 2, '年龄分布', '消费指数');
    // 敏感值处理
    let hasOuthorData = false;
    let hasOuthorData2 = false;
    let dataStatus = 0; // 0:正常 1:数据中包含0  2:数据中全是0或100]
    let dataStatus2 = 0;
    const allData = [...manData, ...menData];
    for (const percent of allData) {
      if (percent !== 0 && percent !== 100) {
        hasOuthorData = true;
      }
      if (percent === 0) {
        dataStatus = 1;
      }
    }
    if (!hasOuthorData) {
      dataStatus = 2;
    }
    for (let i = 0; i < texts2.length; i++) {
      const sum = higherAry[i] + highAry[i] + centerAry[i] + lowerAry[i] + lowAry[i];
      if (sum !== 0 && sum === 100) {
        hasOuthorData2 = true;
      }
      if (sum === 0) {
        dataStatus2 = 1;
      }
    }
    if (!hasOuthorData2) {
      dataStatus2 = 2;
    }
    return {
      option,
      option2,
      datas,
      dataStatus,
      dataStatus2,
    };
  }

  async getPerAgePortrait(
    params: PerAgePortraitSParams,
    titile: string,
    title1s: string,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    callback: any,
    colorAry?: string[],
  ): Promise<any> {
    const res = await portraitByMallId(params);
    const { data, datas } = res;
    const { percents, texts } = data;
    const option = callback(texts, percents, titile, title1s, colorAry);
    // 敏感值处理
    let hasOuthorData = false;
    let dataStatus = 0; // 0:正常 1:数据中包含0  2:数据中全是0或100
    for (const percent of percents) {
      if (percent !== 0 && percent !== 100) {
        hasOuthorData = true;
      }
      if (percent === 0) {
        dataStatus = 1;
      }
    }
    if (!hasOuthorData) {
      dataStatus = 2;
    }
    return {
      option,
      datas,
      dataStatus,
    };
  }

  async arrivalAgePortrait(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    params: any,
    titile: string,
    title1s: string,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    callback: any,
    colorAry?: string[],
  ): Promise<any> {
    const res = await getGridPerAgePortraitInterceptS(params);
    const { data, datas } = res;
    const { percents, texts } = data;
    const option = callback(texts, percents, titile, title1s, colorAry);
    return {
      option,
      datas,
    };
  }
  @Watch('$store.state.mall', { deep: true })
  changeMall(): void {
    this.pageHandler();
  }

  @Watch('$store.state.global.downloadStatus')
  changeExport(): void {
    const datas = [];
    const status = this.$store.state.global.downloadStatus;
    if (status === true) {
      const dateObj = {
        d: this.date,
        xw: `${this.$dayjs(this.date)
          .subtract(6, 'day')
          .format('YYYY-MM-DD')}~${this.date}`,
        xm: this.$dayjs(this.date).format('YYYY-MM'),
      };
      this.excelArr.forEach((i: any) => {
        i.forEach((j: any) => {
          // eslint-disable-next-line no-param-reassign
          j.time = (dateObj as any)[this.duration];
        });
      });
      datas.push({
        sheetData: this.excelArr[0],
        sheetMultiHeader: [
          ['数据项:客流画像-财富属性'],
        ],
        sheetName: '消费水平',
        sheetFilter: ['time', 'text', 'percent'],
        sheetHeader: ['日期', '消费水平', '占比(%)'],
        columnWidths: [10, 8, 8],
      });
      datas.push({
        sheetData: this.excelArr[1],
        sheetMultiHeader: [
          ['数据项:客流画像-财富属性'],
        ],
        sheetName: '购物商场到访频次',
        sheetFilter: ['time', 'text', 'percent'],
        sheetHeader: ['日期', '购物商场到访频次', '占比(%)'],
        columnWidths: [10, 8, 8],
      });
      datas.push({
        sheetData: this.excelArr[2],
        sheetMultiHeader: [
          ['数据项:客流画像-财富属性'],
        ],
        sheetName: '是否有车',
        sheetFilter: ['time', 'text', 'percent'],
        sheetHeader: ['日期', '是否有车', '占比(%)'],
        columnWidths: [10, 8, 8],
      });
      datas.push({
        sheetData: this.excelArr[3],
        sheetMultiHeader: [
          ['数据项:客流画像-财富属性'],
        ],
        sheetName: '居住社区房价',
        sheetFilter: ['time', 'text', 'percent'],
        sheetHeader: ['日期', '居住社区房价', '占比(%)'],
        columnWidths: [10, 8, 8],
      });
      datas.push({
        sheetData: this.excelArr[4],
        sheetMultiHeader: [
          ['数据项:客流画像-财富属性'],
        ],
        sheetName: '餐饮消费水平',
        sheetFilter: ['time', 'text', 'percent'],
        sheetHeader: ['日期', '餐饮消费水平', '占比(%)'],
        columnWidths: [10, 10, 8],
      });
      datas.push({
        sheetData: this.excelArr[5],
        sheetMultiHeader: [
          ['数据项:客流画像-财富属性'],
        ],
        sheetName: '餐饮消费频次',
        sheetFilter: ['time', 'text', 'percent'],
        sheetHeader: ['日期', '餐饮消费频次', '占比(%)'],
        columnWidths: [10, 10, 8],
      });
      datas.push({
        sheetData: this.excelArr[6],
        sheetMultiHeader: [
          ['数据项:客流画像-财富属性'],
        ],
        sheetName: '到访4S店品牌',
        sheetFilter: ['time', 'text', 'percent'],
        sheetHeader: ['日期', '到访4S店品牌', '占比(%)'],
        columnWidths: [10, 10, 8],
      });
      datas.push({
        sheetData: this.excelArr[7],
        sheetMultiHeader: [
          ['数据项:客流画像-财富属性'],
        ],
        sheetName: '手机品牌',
        sheetFilter: ['time', 'text', 'percent'],
        sheetHeader: ['日期', '手机品牌', '占比(%)'],
        columnWidths: [10, 10, 8],
      });
      datas.push({
        sheetData: this.excelArr[8],
        sheetName: '手机价格',
        sheetFilter: ['time', 'text', 'percent'],
        sheetHeader: ['日期', '手机价格', '占比(%)'],
        columnWidths: [10, 10, 8],
      });
      datas.push({
        sheetData: this.excelArr[9],
        sheetMultiHeader: [
          ['数据项:客流画像-财富属性'],
        ],
        sheetName: '各年龄段消费水平分布',
        sheetFilter: ['time', 'text', 'num'],
        sheetHeader: ['日期', '各年龄段消费水平分布', '总数'],
        columnWidths: [10, 10, 8],
      });
      datas.push({
        sheetData: this.excelArr[10],
        sheetMultiHeader: [
          ['数据项:客流画像-财富属性'],
        ],
        sheetName: '不同性别消费详情',
        sheetFilter: [
          'time',
          'text',
          'high',
          'higher',
          'center',
          'lower',
          'low',
        ],
        sheetHeader: [
          '日期',
          '不同性别消费详情',
          '高',
          '较高',
          '中',
          '较低',
          '低',
        ],
        columnWidths: [10, 10, 8],
      });
      this.$root.$data.event.$emit('getDataS', datas);
    }
  }
}
